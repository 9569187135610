import { useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import showResults from "../../../Show";
import store from '../../../../App/store';
import renderSelectField from '../../../../../shared/components/form/Select';
import { setClaimDataAction } from '../../../../../redux/actions/claimAction'
import { isValidEditableReason } from './UpdateMatrix'
import { formatdecimal2place, formatdecimal3place } from '../../../../../utils/formatter'
import * as validation from './validations'
// import {customValidation as customValidation} from './validations'
import { setPage3FormFields } from './setFormFields'
import { EMPLOYEE_GROUPS, isFieldEditable, isFieldHidden } from './getEmpGroup'
import { getSTEPeriodFactorAPI, getdivisorFortnightlyHoursFactorAPI } from './apiCalls'
import { Prompt } from "react-router-dom";
import { validReasonToCall } from './UpdateMatrix'
import { setDataChanged } from '../../../../../redux/actions/claimAction';

let WizardFormThree = ({ handleSubmit, previousPage, change }) => {
  const empDetails = useSelector((state)=>state.employeeDetails.employee)
  const formData = useSelector((state)=>state.form.wizard.values);
  const [initialLoad, setInitialLoad] = useState(true);
  const [ordinaryPay, setOrdinaryPay] = useState(formData.ordinaryPay || 0)  
  const [employmentWeeks, setEmploymentWeeks] = useState(formData.employmentWeeks || 0)
  const [unpaidDays52Weeks, setUnpaidDays52Weeks] = useState(formData.unpaidDays52Weeks || 0)
  const [unpaidDaysFourWeeks, setUnpaidDaysFourWeeks] = useState(formData.unpaidDaysFourWeeks || 0)
  const [unpaidDays52WeeksPreviousEmployer, setUnpaidDays52WeeksPreviousEmployer] = useState(formData.unpaidDays52WeeksPreviousEmployer || 0)
  const [unpaidDaysFourWeeksPrevEmployer, setUnpaidDaysFourWeeksPrevEmployer] = useState(formData.unpaidDaysFourWeeksPrevEmployer || 0)
  const [accDays52Weeks, setAccDays52Weeks] = useState(formData.accDays52Weeks || 0)
  const [accDaysFourWeeks, setAccDaysFourWeeks] = useState(formData.accDaysFourWeeks || 0)
  const [hoursPerFornight, setHoursPerFornight] = useState(formData.hoursPerFornight || 0)
  const [hoursPerMonth, setHoursPerMonth] = useState(formData.hoursPerMonth || 0)
  const [otherEarningsFourWeeks, setOtherEarningsFourWeeks] = useState(formData.otherEarningsFourWeeks || 0)
  const [otherEarnings52Weeks, setOtherEarnings52Weeks] = useState(formData.otherEarnings52Weeks || 0)
  const [hourlyRate, setHourlyRate] = useState(formData.hourlyRate || 0)
  const [annualSalary, setAnnualSalary] = useState(formData.annualSalary || 0)
  const [disableAnnualSalary, setDisableAnnualSalary] = useState(false)
  const [disableHourlyRate, setDisableHourlyRate] = useState(false);
  // const [disableDaysPerWeek, setDisableDaysPerWeek] = useState(false);
  const [disableHoursPerDay, setDisableHoursPerDay] = useState(false);
  const [disableWeeklyHours, setDisableWeeklyHours] = useState(false);
  const [annualisedHours, setAnnualisedHours] = useState(formData.annualizedHours || 0);
  const [weeklyHours, setWeeklyHours] = useState(formData.weeklyHours || 0);
  const [daysPerWeek, setDaysPerWeek] = useState(formData.daysPerWeek ? formData.daysPerWeek.value : empDetails.daysPerWeek)
  const [STEPeriodFactor, setSTEPeriodFactor] = useState(4.000)
  const [divisorFortnightlyHours, setDivisorFortnightlyHours] = useState(Number(localStorage.getItem("localDivisorFortnightlyHours")))
  const updateClaimData = useSelector((state)=>state.claim)    
  const isUpdateToClaim = useSelector((state)=>state.claim.mode === "UPDATE");
  const isCreateClaim = useSelector((state) => state.claim.mode === "CREATE")
  // const [dataChanged, setDataChanged] = useState(isCreateClaim ? true : (isUpdateToClaim && formData.reasonForUpdate && validReasonToCall.includes(formData.reasonForUpdate?.value) ? true : false))
  const [latestPayment, setLatestPayment] = useState([])
  const [hrsRateForTopup, setHrsRateForTopup] = useState(formData.hourlyRate || 0)
  const [employeeGroup, setEmployeeGroup] = useState(false)
  const [earning4weeks, setEarning4weeks] = useState(formData.earning4weeks)
  const [earning52weeks, setEarning52weeks] = useState(formData.earning52weeks)

  let reasonForUpdate = useSelector((state)=>state.form.wizard);
  try{
    reasonForUpdate = reasonForUpdate.values.reasonForUpdate.value
  } catch(e){
    reasonForUpdate = null
  }
    
    useEffect(()=>{
      if(updateClaimData.updateClaimAPIData && updateClaimData.updateClaimAPIData.claim && isUpdateToClaim){
        let filteredPayments = updateClaimData.updateClaimAPIData.payments.filter(line => line.claimNumber).sort((b,a) => (a.endOfWeek > b.endOfWeek) ? 1 : ((b.endOfWeek > a.endOfWeek) ? -1 : 0))
        setPage3FormFields(change, filteredPayments, formData, updateClaimData)
        filteredPayments = filteredPayments[0]
        setLatestPayment(filteredPayments)
        setAnnualisedHours(updateClaimData.updateClaimAPIData.employee.annualizedHours)
        setPaidHoursPerMonthField(updateClaimData.updateClaimAPIData.employee.annualizedHours)
        handlePaidHoursPerForNightChange(updateClaimData.updateClaimAPIData.employee.annualizedHours)
        change("weeklyHours",formatdecimal2place.format(updateClaimData.updateClaimAPIData.employee.annualizedHours/52))
        store.dispatch(setDataChanged(isCreateClaim ? true : (isUpdateToClaim && formData.reasonForUpdate && validReasonToCall.includes(formData.reasonForUpdate?.value) ? true : false)));
      }      
    }, [updateClaimData])

  const setOrdinaryPayField = () => {
    if(formData.ordinaryPay) return
    let ordinaryPayCalc;
    if([EMPLOYEE_GROUPS.GRP1, EMPLOYEE_GROUPS.GRP3, EMPLOYEE_GROUPS.GRP5, EMPLOYEE_GROUPS.GRP7, EMPLOYEE_GROUPS.GRP9].includes(empDetails.group)){
      ordinaryPayCalc = Number(empDetails.hourlyRate * hoursPerMonth).toFixed(2);
    }
    else if([EMPLOYEE_GROUPS.GRP2, EMPLOYEE_GROUPS.GRP4, EMPLOYEE_GROUPS.GRP6, EMPLOYEE_GROUPS.GRP8, EMPLOYEE_GROUPS.GRP10].includes(empDetails.group)){
      ordinaryPayCalc = Number(empDetails.hourlyRate * hoursPerFornight).toFixed(2);      
    }
    else if([EMPLOYEE_GROUPS.GRP11].includes(empDetails.group)){
      ordinaryPayCalc = Number(empDetails.hourlyRate * weeklyHours).toFixed(2); 
    }
    change('ordinaryPay', ordinaryPayCalc);
    setOrdinaryPay(ordinaryPayCalc);
  }

  const setAnnualSalaryField = () => {
    if(formData.annualSalary) return
    if(empDetails.annualSalary){
      change("annualSalary",empDetails.annualSalary)
      setDisableAnnualSalary(true)
      setAnnualSalary(empDetails.annualSalary)
    }else setDisableAnnualSalary(false)
  }
  
  const setHourlyRateField = () => { 
    if(formData.hourlyRate) return

    if([EMPLOYEE_GROUPS.GRP1, EMPLOYEE_GROUPS.GRP2,EMPLOYEE_GROUPS.GRP3, EMPLOYEE_GROUPS.GRP4, EMPLOYEE_GROUPS.GRP5, EMPLOYEE_GROUPS.GRP6,EMPLOYEE_GROUPS.GRP7, EMPLOYEE_GROUPS.GRP8].includes(empDetails.group)){      
      change("hourlyRate",Number(empDetails.annualSalary/empDetails.annualizedHours).toFixed(6))
      setHourlyRate(Number(empDetails.annualSalary/empDetails.annualizedHours).toFixed(6))
    }
    else if([EMPLOYEE_GROUPS.GRP9, EMPLOYEE_GROUPS.GRP10,EMPLOYEE_GROUPS.GRP11, EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14].includes(empDetails.group)){
      change("hourlyRate",empDetails.hourlyRate)
      setHourlyRate(empDetails.hourlyRate)
    }
    
  }

  const setDaysPerWeekField = () => {
    if (empDetails.daysPerWeek){
      if(empDetails.daysPerWeek == 5) change("daysPerWeek", { value: '5', label: '5' })
      else if(empDetails.daysPerWeek == 7) change("daysPerWeek", { value: '7', label: '7' })
      setDaysPerWeek(empDetails.daysPerWeek)    
      // setDisableDaysPerWeek(true)
    } 
    // else setDisableDaysPerWeek(false)
   }

   const setHoursPerDayField = () => {
     if(!daysPerWeek) return
     change("hoursPerDay", formatdecimal2place.format(weeklyHours/daysPerWeek))
   }

   const setAnnualizedHoursField = () => {
    let hours = 0
    if(empDetails.group === EMPLOYEE_GROUPS.GRP1) hours = 2080
    else if([EMPLOYEE_GROUPS.GRP2, EMPLOYEE_GROUPS.GRP4, EMPLOYEE_GROUPS.GRP6, EMPLOYEE_GROUPS.GRP8].includes(empDetails.group)) hours = hoursPerFornight * 26
    else if([EMPLOYEE_GROUPS.GRP3, EMPLOYEE_GROUPS.GRP5, EMPLOYEE_GROUPS.GRP7].includes(empDetails.group)) hours = hoursPerMonth * 12
    else if([EMPLOYEE_GROUPS.GRP9, EMPLOYEE_GROUPS.GRP10, EMPLOYEE_GROUPS.GRP11].includes(empDetails.group)) hours = (empDetails && empDetails.annualizedHours)?empDetails.annualizedHours:0
    else if([EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14].includes(empDetails.group)) hours = 0
    else hours = 0
    if(hours !== 0){
      change("annualizedHours",hours)
      setAnnualisedHours(hours)
    }
   }

   const setPaidHoursPerMonthField = (hours = null) => {  
    if(formData.hoursPerMonth) return

    // This is during update claim
    if(isUpdateToClaim && hours) {
      change("hoursPerMonth",formatdecimal2place.format(hours/12))
      setHoursPerMonth(formatdecimal2place.format(hours/12))
      return;
    }

    const hrs = formData.annualizedHours ? formData.annualizedHours : empDetails.annualizedHours    
    if([EMPLOYEE_GROUPS.GRP1, EMPLOYEE_GROUPS.GRP3, EMPLOYEE_GROUPS.GRP5, EMPLOYEE_GROUPS.GRP7, EMPLOYEE_GROUPS.GRP9].includes(empDetails.group)){
      change("hoursPerMonth", formatdecimal2place.format(hrs/12))
      setHoursPerMonth(formatdecimal2place.format(hrs/12))
    }else{
      change("hoursPerMonth", 0);
      setHoursPerMonth(0);
    }
  }

  const setPaidHoursPerFortNightField = () => {
    if(formData.hoursPerFornight) return    
    else if(empDetails.group === EMPLOYEE_GROUPS.GRP10) change("hoursPerFornight", empDetails.annualizedHours/26)    
   }

   const setWeeklyHoursField = () => {    
      change("weeklyHours",formatdecimal2place.format(annualisedHours/52))
      setWeeklyHours(formatdecimal2place.format(annualisedHours/52))
   }

   const handleHrsPerDayForAbatementChange = () => {     
     if(weeklyHours && daysPerWeek)
       change("hrsPerDayForAbatement",formatdecimal2place.format(weeklyHours/daysPerWeek))
   }

   const handleHoursPerDay = (type,value) => {
    if(type == "weeklyHours") change("hoursPerDay",formatdecimal2place.format(value/daysPerWeek))
    else if(type == "daysPerWeek") change("hoursPerDay",formatdecimal2place.format(weeklyHours/value))
    //This is when annual hours is updated
    else{
      if(weeklyHours && daysPerWeek)
        change("hoursPerDay", formatdecimal2place.format(weeklyHours/daysPerWeek))
    }
   }

   
   const handleHourlyRateForTopUpField = () => {
     let hours;
     let hourlyRateTopup;
     let ordinaryPaytmp;
      if (annualisedHours != 0){
        if(empDetails.payFrequency == "M") hours = annualisedHours/12
        else if(empDetails.payFrequency == "F") hours = annualisedHours/26
        else if(empDetails.payFrequency == "W") hours = annualisedHours/52
        ordinaryPaytmp = formData.ordinaryPay || ordinaryPay;
        hourlyRateTopup =  hours ? (parseFloat(ordinaryPaytmp))/hours : 0
      }else{
        if(!isUpdateToClaim){
          hourlyRateTopup = empDetails.hourlyRate
        }else{
          hourlyRateTopup = updateClaimData.updateClaimAPIData.employee.hourlyRate
        }
      }    
     //change("hrsRateForTopup", Number(hourlyRateTopup).toFixed(6))
   }

   const handlePaidHoursPerForNightChange = (hours) => {
     let val = formatdecimal2place.format(hours/divisorFortnightlyHours);
     change("hoursPerFornight",val)
     setHoursPerFornight(val)
   }

   const handleAnnualizedHours = (e) => {
    updateEmployeeGroup();
    setAnnualisedHours(e.target.value)  
    if(empDetails.payFrequency=="M") {
      let hrspermonthVal =  formatdecimal2place.format(e.target.value/12)
      change("hoursPerMonth", hrspermonthVal)
      setHoursPerMonth(hrspermonthVal)
    }
    if(empDetails.payFrequency=="F") {
      handlePaidHoursPerForNightChange(e.target.value)
    }
    let weeklyHoursUpdated = formatdecimal2place.format(e.target.value/52);
    setWeeklyHours(weeklyHoursUpdated);
    change("weeklyHours", weeklyHoursUpdated);
   }

   const updateEmployeeGroup = ()=>{
    setEmployeeGroup(true)
    if([EMPLOYEE_GROUPS.GRP14].includes(empDetails.group)){
      empDetails.group = EMPLOYEE_GROUPS.GRP11
    }
    else if([EMPLOYEE_GROUPS.GRP13].includes(empDetails.group)){
      empDetails.group = EMPLOYEE_GROUPS.GRP10
    }else if([EMPLOYEE_GROUPS.GRP12].includes(empDetails.group)){
      empDetails.group = EMPLOYEE_GROUPS.GRP9
    }
  }

  const handleOrdinaryPay = () => {    
    if([EMPLOYEE_GROUPS.GRP1, EMPLOYEE_GROUPS.GRP3, EMPLOYEE_GROUPS.GRP5, EMPLOYEE_GROUPS.GRP7, EMPLOYEE_GROUPS.GRP9].includes(empDetails.group)){
      change('ordinaryPay',Number(hourlyRate * hoursPerMonth).toFixed(2))
      setOrdinaryPay(Number(hourlyRate * hoursPerMonth).toFixed(2))
    }
    else if([EMPLOYEE_GROUPS.GRP2, EMPLOYEE_GROUPS.GRP4, EMPLOYEE_GROUPS.GRP6, EMPLOYEE_GROUPS.GRP8, EMPLOYEE_GROUPS.GRP10].includes(empDetails.group)){
      change('ordinaryPay',Number(hourlyRate * hoursPerFornight).toFixed(2))
      setOrdinaryPay(Number(hourlyRate * hoursPerFornight).toFixed(2))
    }    
    else if([EMPLOYEE_GROUPS.GRP11].includes(empDetails.group)){
      change('ordinaryPay',Number(empDetails.hourlyRate * weeklyHours).toFixed(2)) 
    }
  }

  const handleHourlyRate = () => {
    if(!annualSalary) return
      change("hourlyRate",Number(annualSalary/annualisedHours).toFixed(6))
      setHourlyRate(Number(annualSalary/annualisedHours).toFixed(6))
  }
    
  const setHrsPerDayForAbatementField = () => {
    if(formData.hrsPerDayForAbatement) return
    change("hrsPerDayForAbatement",formatdecimal2place.format(formData.weeklyHours/empDetails.daysPerWeek))
  }

  const setDivisor52Weeks = () => {
    let employmentWeeks52;
    if(parseFloat(employmentWeeks) < 52 && otherEarnings52Weeks == 0){
      employmentWeeks52 = parseFloat(employmentWeeks);
    } else {
      employmentWeeks52 = 52;
    }
    const divisor = parseFloat(employmentWeeks52) - (parseFloat(unpaidDays52Weeks || 0) + parseFloat(unpaidDays52WeeksPreviousEmployer || 0) + parseFloat(accDays52Weeks || 0))/parseFloat(daysPerWeek)
    change("divisor52weeks",formatdecimal3place.format(divisor))
  }
  
  const setDivisor4Weeks = () => {  
    let employmentWeeks4;
    if(parseFloat(employmentWeeks) < 4 && otherEarningsFourWeeks == 0){
      employmentWeeks4 = parseFloat(employmentWeeks);
    } else {
      employmentWeeks4 = parseFloat(STEPeriodFactor);
    }
    const divisor = parseFloat(employmentWeeks4) - (parseFloat(unpaidDaysFourWeeks || 0) + parseFloat(unpaidDaysFourWeeksPrevEmployer || 0) + parseFloat(accDaysFourWeeks || 0))/parseFloat(daysPerWeek)
    change("divisor4weeks",formatdecimal3place.format(divisor))
  }

  const setEffectiveDateField = () => {
    if(formData.effectiveDate) return
    else change("effectiveDate",formData.dateOfInjury)
  }

  const isCalculationBasisChanged = () => {
    if(!isUpdateToClaim) return false;
    const newCalculationBasis = formData.calculationBasis == "STE" ? "S" : formData.calculationBasis == "LTE" ? "L": "B";
    const oldCalculationBasis = updateClaimData?.updateClaimAPIData?.claim?.calculationBasis;
    return (newCalculationBasis && oldCalculationBasis && newCalculationBasis !== oldCalculationBasis) ? true : false
  }

  useEffect(()=>{
    getdivisorFortnightlyHoursFactorAPI(formData, divisorFortnightlyHours, setDivisorFortnightlyHours)
    getSTEPeriodFactorAPI(formData, isUpdateToClaim, change, setSTEPeriodFactor)
    if(isUpdateToClaim) return
    store.dispatch(setClaimDataAction({}))
    // setOrdinaryPayField() cannot set this field as hrsPerFortnight and hrsPerMonth is does received from API
    setHourlyRateField()
    setAnnualSalaryField()
    setEffectiveDateField()
    //Commented for now - CARD - 46225
    // setDaysPerWeekField()
    setPaidHoursPerMonthField()
    setPaidHoursPerFortNightField()
    // setWeeklyHoursField()
    setHrsPerDayForAbatementField()
  }, [empDetails])

  useEffect(()=>{
    if(isUpdateToClaim) return
    setAnnualizedHoursField()
  }, [hoursPerMonth, hoursPerFornight, empDetails])
  
  
  useEffect(()=>{
    if(isUpdateToClaim) return
    setHoursPerDayField()
  }, [weeklyHours, daysPerWeek])

  useEffect(()=>{
    if(isUpdateToClaim) return
    setDivisor52Weeks()
  }, [daysPerWeek, employmentWeeks, unpaidDays52Weeks, unpaidDays52WeeksPreviousEmployer, accDays52Weeks, earning52weeks])

  useEffect(()=>{
    if(isUpdateToClaim) return    
    setDivisor4Weeks()
  }, [daysPerWeek, STEPeriodFactor, employmentWeeks, unpaidDaysFourWeeks, unpaidDaysFourWeeksPrevEmployer, accDaysFourWeeks, earning4weeks])

  useEffect(()=>{
    if(isUpdateToClaim) return
    setWeeklyHoursField()
    // handleHoursPerDay()
    
  }, [annualisedHours])

  useEffect(()=>{
    handlePaidHoursPerForNightChange(annualisedHours)
  }, [annualisedHours, divisorFortnightlyHours])

  useEffect(()=>{
    if(isUpdateToClaim) return
    if(weeklyHours && daysPerWeek) 
      change("hrsPerDayForAbatement",formatdecimal2place.format(weeklyHours/daysPerWeek))
  }, [weeklyHours, daysPerWeek])

  useEffect(()=>{
    if(isUpdateToClaim || (isUpdateToClaim && reasonForUpdate != null && reasonForUpdate === "topUpOrdinaryPay")) return
      handleOrdinaryPay()
  }, [hoursPerFornight, hoursPerMonth, hourlyRate, annualisedHours, weeklyHours])

  useEffect(()=>{
    if(isUpdateToClaim) return
    handleHourlyRate()
  }, [annualSalary, annualisedHours])

  useEffect(()=>{
    if(isUpdateToClaim) return
    setDivisor4Weeks()
  }, [otherEarningsFourWeeks])

  useEffect(()=>{
    if(isUpdateToClaim) return
    setDivisor52Weeks()
  }, [otherEarnings52Weeks]) 
 
  useEffect(()=>{
    store.dispatch(setDataChanged(isCreateClaim ? true : (isUpdateToClaim && formData.reasonForUpdate && validReasonToCall.includes(formData.reasonForUpdate?.value) ? true : false)));
  },[isUpdateToClaim, isCreateClaim])
  
  return(
    <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
      {/* <div>
        <Prompt
            when={dataChanged}
            message={location => `Changes made will be lost. Are you sure you want to go to ${location.pathname} ?`}
        />
      </div> */}
      <h3 className="wizard__title">Earnings</h3>
      <div className="form__form-group">
        <span className="form__form-group-label">Employment Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="employmentWeeks"
            component={validation.renderField}
            type="text"
            placeholder="123456"
            onChange={(e)=>setEmploymentWeeks(e.target.value)}
            disabled={isUpdateToClaim}
            validate={[validation.customValidation]}
          />
        </div>
      </div>
      <div hidden={isFieldHidden('ordinaryPay',empDetails.group)} className="form__form-group">
        <span className="form__form-group-label">Ordinary Pay</span>
        <div className="form__form-group-field">
          <Field
            name="ordinaryPay"
            component={validation.renderField}
            //making this optional since it is calculated
            //validate={[validation.required]}
            type="number"
            placeholder="123456"
            onChange={(e)=>setOrdinaryPay(e.target.value)}
            disabled={(isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "ordinaryPay")) || (!isUpdateToClaim && !isFieldEditable('ordinaryPay',empDetails.group))}
          />
        </div>
      </div>     
      <div className="form__form-group">
        <span className="form__form-group-label">Effective Date Of Ordinary Pay</span>
        <div className="form__form-group-field">
          <Field
            name="effectiveDate"
            component={validation.renderField}
            validate={[validation.required]}
            type="date"
            disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "effectiveDate")}
            max={formData?.certificateValidTo}
          />
        </div>
      </div>
      <div hidden={empDetails.paymentType!='S'} className="form__form-group">
        <span className="form__form-group-label">Annual Salary</span>
        <div className="form__form-group-field">
          <Field
            name="annualSalary"
            component={validation.renderField}
            validate={[validation.customValidation]}
            type="number"
            placeholder="123456"
            onChange={(e)=>setAnnualSalary(e.target.value)}
            disabled={disableAnnualSalary || isUpdateToClaim}
          />
        </div>
      </div>
      <div hidden={isFieldHidden('hourlyRate',empDetails.group)} className="form__form-group">
        <span className="form__form-group-label">Hourly Rate</span>
        <div className="form__form-group-field">
          <Field
            name="hourlyRate"
            component={validation.renderField}
            validate={[validation.customValidation]}
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim || !isFieldEditable('hourlyRate',empDetails.group)}          
            onChange={(e)=>setHourlyRate(e.target.value)}
          />
        </div>
      </div>
      <div hidden={isFieldHidden('daysPerWeek',empDetails.group)} className="form__form-group">
        <span className="form__form-group-label">Days Per Week</span>
        <div className="form__form-group-field">
          <Field
            name="daysPerWeek"
            component={renderSelectField}
            validate={[validation.required]}
            type="number"            
            placeholder="5,7"
            options={[
              { value: '5', label: '5' },
              { value: '7', label: '7' },
            ]}
            isDisabled={isUpdateToClaim || !isFieldEditable('daysPerWeek',empDetails.group)}
            onChange={(e)=>{setDaysPerWeek(e.value);/*handleHoursPerDay('daysPerWeek',e.value)*/}}
          />
        </div>
      </div>      
      <div hidden={isFieldHidden('annualizedHours',empDetails.group)} className="form__form-group">
        <span className="form__form-group-label">Annualized Hours</span>
        <div className="form__form-group-field">
          <Field
            name="annualizedHours"
            component={validation.renderField}            
            type="number"
            placeholder="123456"
            onChange={handleAnnualizedHours}
            disabled={isUpdateToClaim || (!isFieldEditable('annualizedHours',empDetails.group) && !employeeGroup)}
          />
        </div>
      </div>
      <div hidden={isFieldHidden('hoursPerMonth',empDetails.group)} className="form__form-group">
        <span className="form__form-group-label">Paid Hours per Month</span>
        <div className="form__form-group-field">
          <Field
            name="hoursPerMonth"
            component="input"
            type="number"
            placeholder="123456"
            // disabled={true}
            disabled={isUpdateToClaim || !isFieldEditable('hoursPerMonth',empDetails.group)}
            onChange={(e)=>setHoursPerMonth(e.target.value)}
          />
        </div>
      </div>
      <div hidden={isFieldHidden('hoursPerFornight',empDetails.group)} className="form__form-group">
        <span className="form__form-group-label">Paid Hours per Fortnight</span>
        <div className="form__form-group-field">
          <Field
            name="hoursPerFornight"
            component="input"
            type="number"
            placeholder="123456"
            // disabled={true}
            disabled={isUpdateToClaim || !isFieldEditable('hoursPerFornight',empDetails.group)}
            onChange={(e)=>setHoursPerFornight(e.target.value)}
          />
        </div>
      </div>
      <div hidden={isFieldHidden('weeklyHours',empDetails.group)} className="form__form-group">
        <span className="form__form-group-label">Weekly Hours</span>
        <div className="form__form-group-field">
          <Field
            name="weeklyHours"
            component="input"
            type="number"
            placeholder="123456"
            // disabled={disableWeeklyHours || isUpdateToClaim}
            disabled={isUpdateToClaim || !isFieldEditable('weeklyHours',empDetails.group)}
            onChange={(e)=>{setWeeklyHours(e.target.value);/*handleHoursPerDay('weeklyHours',e.target.value)*/}}
          />
        </div>
      </div>
      <div hidden={isFieldHidden('hoursPerDay',empDetails.group)} className="form__form-group">
        <span className="form__form-group-label">Hours per Day</span>
        <div className="form__form-group-field">
          <Field
            name="hoursPerDay"
            component={validation.renderField}
            validate={[validation.required]}
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim || !isFieldEditable('hoursPerDay',empDetails.group)}
          />
        </div>
      </div>
      <div hidden={!(annualisedHours==0)} className="form__form-group">
        <span className="form__form-group-label">Stand Down Hours</span>
        <div className="form__form-group-field">
          <Field
            name="standDownHours"
            component="input"
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "STE" || formData.calculationBasis == "STE & LTE")} className="form__form-group">
        <span className="form__form-group-label">STE Earning 4 weeks</span>
        <div className="form__form-group-field">
          <Field
            name="earning4weeks"
            validate={[validation.customValidation]}
            component={validation.renderField}
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "earning4weeks")}
            onChange={(e)=>setEarning4weeks(e.target.value)}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "LTE" || formData.calculationBasis == "STE & LTE" || annualisedHours == 0)} className="form__form-group">
        <span className="form__form-group-label">LTE Earning 52 weeks</span>
        <div className="form__form-group-field">
          <Field
            name="earning52weeks"
            validate={[validation.customValidation]}
            component={validation.renderField}
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "earning52weeks") && !isCalculationBasisChanged()}
            onChange={(e)=>setEarning52weeks(e.target.value)}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "STE" || formData.calculationBasis == "STE & LTE")} className="form__form-group">
        <span className="form__form-group-label">Unpaid days 4 weeks</span>
        <div className="form__form-group-field">
          <Field
            name="unpaidDaysFourWeeks"
            component="input"
            type="number"
            placeholder="123456"
            onChange={(e)=>setUnpaidDaysFourWeeks(e.target.value)}
            disabled={isUpdateToClaim}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "LTE" || formData.calculationBasis == "STE & LTE" || annualisedHours == 0)} className="form__form-group">
        <span className="form__form-group-label">Unpaid Days 52 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="unpaidDays52Weeks"
            component="input"
            type="number"
            placeholder="123456"
            onChange={(e)=>setUnpaidDays52Weeks(e.target.value)}
            disabled={isUpdateToClaim && !isCalculationBasisChanged()}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "STE" || formData.calculationBasis == "STE & LTE")} className="form__form-group">
        <span className="form__form-group-label">ACC Days 4 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="accDaysFourWeeks"
            component="input"
            type="number"
            placeholder="123456"
            onChange={(e)=>setAccDaysFourWeeks(e.target.value)}
            disabled={isUpdateToClaim}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "LTE" || formData.calculationBasis == "STE & LTE"  || annualisedHours == 0)} className="form__form-group">
        <span className="form__form-group-label">ACC Days 52 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="accDays52Weeks"
            component="input"
            type="number"
            placeholder="123456"
            onChange={(e)=>setAccDays52Weeks(e.target.value)}
            disabled={isUpdateToClaim && !isCalculationBasisChanged()}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "STE" || formData.calculationBasis == "STE & LTE")} className="form__form-group">
        <span className="form__form-group-label">Other Earnings 4 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="otherEarningsFourWeeks"
            component="input"
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "otherEarningsFourWeeks")}
            onChange={(e)=>setOtherEarningsFourWeeks(e.target.value)}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "LTE" || formData.calculationBasis == "STE & LTE" || annualisedHours == 0)} className="form__form-group">
        <span className="form__form-group-label">Other Earnings 52 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="otherEarnings52Weeks"
            component="input"
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "otherEarnings52Weeks")  && !isCalculationBasisChanged()}
            onChange={(e)=>setOtherEarnings52Weeks(e.target.value)}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "STE" || formData.calculationBasis == "STE & LTE")} className="form__form-group">
        <span className="form__form-group-label">Unpaid Days 4 Weeks (previous employer)</span>
        <div className="form__form-group-field">
          <Field
            name="unpaidDaysFourWeeksPrevEmployer"
            component="input"
            type="number"
            placeholder="123456"
            onChange={(e)=>setUnpaidDaysFourWeeksPrevEmployer(e.target.value)}
            disabled={isUpdateToClaim}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "LTE" || formData.calculationBasis == "STE & LTE"  || annualisedHours == 0)} className="form__form-group">
        <span className="form__form-group-label">Unpaid Days 52 Weeks (previous employer)</span>
        <div className="form__form-group-field">
          <Field
            name="unpaidDays52WeeksPreviousEmployer"
            component="input"
            type="number"
            placeholder="123456"
            onChange={(e)=>setUnpaidDays52WeeksPreviousEmployer(e.target.value)}
            disabled={isUpdateToClaim  && !isCalculationBasisChanged()}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "STE" || formData.calculationBasis == "STE & LTE")} className="form__form-group">
        <span className="form__form-group-label">Divisor 4 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="divisor4weeks"
            component="input"
            type="number"
            placeholder="123456"
            disabled={true}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "LTE" || formData.calculationBasis == "STE & LTE"  || annualisedHours == 0)} className="form__form-group">
        <span className="form__form-group-label">Divisor 52 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="divisor52weeks"
            component="input"
            type="number"
            placeholder="123456"
            disabled={true}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "STE" || formData.calculationBasis == "STE & LTE")} className="form__form-group">
        <span className="form__form-group-label">Overwrite Divisor 4 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="overwriteDivisor4Weeks"
            component="input"
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "overwriteDivisor4Weeks")}
          />
        </div>
      </div>
      <div hidden={!(formData.calculationBasis == "LTE" || formData.calculationBasis == "STE & LTE" || annualisedHours == 0)} className="form__form-group">
        <span className="form__form-group-label">Overwrite Divisor 52 Weeks</span>
        <div className="form__form-group-field">
          <Field
            name="overwriteDivisor52Weeks"
            component="input"
            type="number"
            placeholder="123456"
            disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "overwriteDivisor52Weeks")  && !isCalculationBasisChanged()}
          />
        </div>
      </div>
      <div hidden={!(empDetails.paymentType=='W')} className="form__form-group">
        <span className="form__form-group-label">Hours per day for Abatement</span>
        <div className="form__form-group-field">
          <Field
            name="hrsPerDayForAbatement"
            component="input"
            type="number"
            placeholder="123456"
            disabled={true}
          />
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" className="previous" onClick={previousPage}>Back</Button>
        <Button color="primary" type="submit" className="next">Next</Button>
      </ButtonToolbar>
    </form>
  );
}

WizardFormThree.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true
  // customValidation
})(WizardFormThree);
