import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardHeader, Col, Button, ButtonToolbar } from 'reactstrap';
import Collapse from '../../../../../shared/components/Collapse';
import renderSelectField from '../../../../../shared/components/form/Select';
import { Field, reduxForm } from 'redux-form';
import { validReasonToCall } from './UpdateMatrix'
import store from '../../../../App/store';
import { setCalcPayments, setCalcTotals, setReCalcPayments, setReCalcTotals, setNewClaimPayments, setNewClaimTotals, setDataChanged } from '../../../../../redux/actions/claimAction'
import { EMPLOYEE_GROUPS } from './getEmpGroup'
import moment from 'moment';
import {formatDateAsText, formatDateForAPI as formatDateColumn} from '../../../../../utils/formatDate';
import {PAY_TYPE, INJURY_TYPE} from '../../../../../utils/constant';
import { Prompt } from "react-router-dom";

const WizardFormResults = ({ handleSubmit, previousPage, change }) => {
    const [isCollapsed, setIsCollapsed] = useState('collapse')
    const employeeDetails = useSelector((state) => state.employeeDetails.employee)
    const formData = useSelector((state) => state.form.wizard.values)
    const [weeklyCompAPIData, setWeeklyCompAPIData] = useState()
    const [isPSLTopUp, setIsPSLTopup] = useState(formData.pslTopUp)
    const claimData = useSelector((state) => state.claim.claimData)
    const isUpdateToClaim = useSelector((state) => state.claim.mode === "UPDATE")
    const isCreateClaim = useSelector((state) => state.claim.mode === "CREATE")
    const isViewClaim = useSelector((state) => state.claim.mode === "VIEW")
    const updateClaimData = useSelector((state) => state.claim)
    const [mappedNotes, setMappedNotes] = useState();
    const [STErateFull, setSTERateFull] = useState()
    const [LTErateFull, setLTERateFull] = useState()
    // const [dataChanged, setDataChanged] = useState(isViewClaim ? false : (isCreateClaim ? true : (isUpdateToClaim && formData.reasonForUpdate && validReasonToCall.includes(formData.reasonForUpdate?.value) ? true : false)))    
    const [ordinaryPay, setOrdinaryPay] = useState(formData.ordinaryPay || 0)    
    const [indexationExemptionDates, setIndexationExemptionDates] = useState();
    const [eraOptOut, setERAOptOut] = useState(false);
    const [migratedClaim, setMigratedClaim] = useState(updateClaimData?.updateClaimAPIData?.claim?.migratedClaim === "X");
    const saveDisable = useSelector((state) => state.claim.apiCalled)
    const indexationColumns = [
        { title: 'From Date', field: 'fromDate', type: 'date', editable: 'never', sorting: false },
        { title: 'To Date', field: 'toDate', type: 'date', editable: 'never', sorting: false },
    ];
    const columns = [
        { title: 'PP Start', field: 'startOfPayrollPeriod', type: 'date', editable: 'never', sorting: false },
        { title: 'PP End', field: 'endOfPayrollPeriod', type: 'date', editable: 'never', sorting: false },
        { title: 'Weeks', field: 'weeks', type: 'text', editable: 'never', sorting: false },
        { title: 'Start Date', field: 'startOfWeek', type: 'date', editable: 'never', sorting: false },
        { title: 'End Date', field: 'endOfWeek', type: 'date', editable: 'never', sorting: false },
        {
            title: 'Pay Type', field: 'PaymentType', type: 'string', render: (rowData) => {
                if (rowData.PaymentType == 'L' || rowData.paymentType == 'L') return "LTE"
                else if (rowData.PaymentType == 'S' || rowData.paymentType == 'S') return "STE"
                else if (rowData.PaymentType == 'D' || rowData.paymentType == 'D') return "Stand Down"
                else if (!rowData.startOfWeek) return "Total Payable"
            }, editable: 'never', sorting: false
        },
        { title: 'No. of days', field: 'workingDays', type: 'numeric', editable: 'never', sorting: false },
        {
            title: '80% Rate', field: 'accWeeklyCompensation', type: 'numeric', render: (rowData) => {
                let value = rowData.startOfWeek ? (rowData.accWeeklyCompensation) : 0;
                return value === 0 ? "" : value;
            }, editable: 'never', sorting: false
        },
        { 
            title: 'Hrs. Worked', field: 'actualHours', type: 'numeric', 
            editable: (formData.reasonForUpdate && formData.reasonForUpdate.value !== 'abatementTerminatedEmployee') ? 'onUpdate' : '', 
            sorting: false },
        { 
            title: 'Addtl. Payments', field: 'additionalPayments', type: 'numeric', 
            editable: (formData.reasonForUpdate && formData.reasonForUpdate.value !== 'abatementTerminatedEmployee') ? 'onUpdate' : '',
            sorting: false },
        {
            title: 'Post Incap.', field: 'postIncapacityEarnings', type: 'numeric',
            editable: (formData.reasonForUpdate && formData.reasonForUpdate.value) === 'abatementTerminatedEmployee' ? 'onUpdate' : '',
            sorting: false
        },
        { title: '20% of Full Rate', field: 'rate20', type: 'numeric', editable: 'never', sorting: false },
        {
            title: 'Abated Comp.', field: 'abatedACCWeeklyCompensation', type: 'numeric', render: (rowData) => {
                let value = rowData.startOfWeek ? rowData.abatedACCWeeklyCompensation : 0;
                return value === 0 ? "" : value
            }, editable: 'never', sorting: false
        },
        {
            title: 'Net Comp.', field: 'netCompensation', type: 'numeric', render: (rowData) => {
                return rowData.startOfWeek ? rowData.netWeeklyCompensation : rowData.netCompensation
            }, editable: 'never', sorting: false
        },
        {
            title: 'Payroll Code', field: 'payrollCode', type: 'string', render: (rowData) => {
                return rowData.paymentType == "D" && Number(rowData.netWeeklyCompensation) > 0 ? "ACW" : rowData.payrollCode
            }, editable: 'never', sorting: false
        },
        { title: 'Top Up', field: 'topUp', type: 'numeric', editable: 'never', sorting: false },
        { title: 'Top Up Pay. Code', field: 'payrollCodeTopUp', type: 'string', editable: 'never', sorting: false },
        { title: 'PSL Hours', field: 'pslHours', type: 'string', hidden: !isPSLTopUp, editable: 'never', sorting: false },
        // { title: 'PSL Balance', field: 'pslClosingBalance', type: 'numeric', hidden:!isPSLTopUp, editable: 'never', sorting: false },
    ]

    const getMappedNotes = (notes) => {
        if (!notes || notes.length === 0) return null;
        let mappedNotes = [];
        notes.forEach((row, key) => {
            const note = row.notes?.split('ChangedBy: ')[0]
            const changedOn = moment(row.notes?.split('Changed on: ')[1])?.local().format("DD/MM/YYYY")
            const changedBy = row.notes?.split('ChangedBy: ')[1]?.split(',')[0]
            mappedNotes.push({note, changedOn, changedBy})
        })
        mappedNotes = mappedNotes.sort((a,b) => {
            let A = a.changedOn.split('/');
            A = A[2] + '-' + A[1] + '-' + A[0]
            let B = b.changedOn.split('/')
            B = B[2] + '-' + B[1] + '-' + B[0]
            return new Date(A) - new Date(B)
        })
        return mappedNotes;
    }

    const sortAndFormatCalculations = (paymentArray) => {
        let payments = JSON.parse(JSON.stringify(paymentArray));
        payments = payments.filter(line => line.claimNumber)
        payments = payments.sort((a,b) => {
            if(!a.startOfWeek || !b.startOfWeek) return 0
            let A = a.startOfWeek.split('/')
            A = A[2] + '-' + A[1] + '-' + A[0]
            let B = b.startOfWeek.split('/')
            B = B[2] + '-' + B[1] + '-' + B[0]
            return new Date(A) - new Date(B)
        })
        payments = payments.sort((a,b) => {
            if(!a.endOfPayrollPeriod || !b.endOfPayrollPeriod) return 0
            let A = a.endOfPayrollPeriod.split('/')
            A = A[2] + '-' + A[1] + '-' + A[0]
            let B = b.endOfPayrollPeriod.split('/')
            B = B[2] + '-' + B[1] + '-' + B[0]
            return new Date(A) - new Date(B)
        })
        const formattedPayments = payments.map((row) => {
            row.startOfWeek             = formatDateColumn(row.startOfWeek);
            row.endOfWeek               = formatDateColumn(row.endOfWeek);
            row.startOfPayrollPeriod    = formatDateColumn(row.startOfPayrollPeriod);
            row.endOfPayrollPeriod      = formatDateColumn(row.endOfPayrollPeriod);
            return row;
        })    
        return formattedPayments;
    }

    const getRate = (paymentType) => {
        if (paymentType === "L") return LTErateFull
        else if (paymentType === "S") return STErateFull
        else if (paymentType === "D") return 0
    }

    const getUpdatedAbatedACCCompensation = (paymentType, rateFull, postIncapacityEarnings, workingDays, daysPerWeek) => {
        if (paymentType === 'D' || !daysPerWeek || daysPerWeek === 0) return 0;
        workingDays = workingDays ? workingDays : 0;
        const abatedACCCompensation = Number((Number(rateFull) - Number(postIncapacityEarnings)) <= 0 ? 0 : (Number(rateFull * workingDays/daysPerWeek) - Number(postIncapacityEarnings)).toFixed(2))
        return abatedACCCompensation < 0 ? 0 : abatedACCCompensation;
    }

    const getNetWeeklyCompensation = (paymentType, accWeeklyCompensation, abatedACCWeeklyCompensation, postIncapacityEarnings, rate20) => {
        if (paymentType === 'D') return (accWeeklyCompensation - postIncapacityEarnings) <= 0 ? 0 : Number(accWeeklyCompensation - postIncapacityEarnings).toFixed(2)
        return (Number(postIncapacityEarnings) >= Number(rate20)) ? Number(abatedACCWeeklyCompensation).toFixed(2) : Number(accWeeklyCompensation).toFixed(2)
    }

    const handleCollapse = (e) => {
        setIsCollapsed(e.target.name)
        setTimeout(
            function () {
                setIsCollapsed('blank')
            }
            , 100)
    }

    const handleCompensationCalc = (newData, oldData) => {
        if (!newData.actualHours || newData.actualHours < 0) newData.actualHours = 0
        if (!newData.additionalPayments || newData.additionalPayments < 0) newData.additionalPayments = 0
        newData.netWeeklyCompensation = 0
        newData.abatedACCWeeklyCompensation = 0
        if ((newData.actualHours == 0) && (newData.additionalPayments == 0)) {
            setWeekData(oldData.weeks, oldData.accWeeklyCompensation)                  
        } else {
            setWeekData(oldData.weeks, oldData.netWeeklyCompensation)            
        }
        //Need to use Hourly Rate for Topup for Post Incapacity Earnings and PSL Hours except for 
        //employees without annualized hours. 
        //For those employees without annualized hours need to use hourly rate from e-HR
        //const hourlyRate = isUpdateToClaim ? updateClaimData.updateClaimAPIData.employee.hourlyRate : formData.hourlyRate
        let hourlyRateTopUp = 0;
        if (formData.annualizedHours === 0) {
            hourlyRateTopUp = isUpdateToClaim ? updateClaimData.updateClaimAPIData.employee.hourlyRate : formData.hourlyRate;
        } else {
            hourlyRateTopUp = oldData.abatementHourlyRate ? oldData.abatementHourlyRate : formData.hourlyRate
        }
        const postIncapacityEarnings = (formData.reasonForUpdate && formData.reasonForUpdate.value === 'abatementTerminatedEmployee') ? Number(newData.postIncapacityEarnings) : Number(newData.actualHours * hourlyRateTopUp + newData.additionalPayments)
        const rateFull = oldData.rateFull 
        let rate20 = oldData.rate20
        if((!rate20 || rate20 === 0) && employeeDetails?.daysPerWeek){
            rate20 = Number((rateFull * (oldData.workingDays / employeeDetails?.daysPerWeek) * (20 / 100)).toFixed(2));
        }
        const abatedACCWeeklyCompensation = Number(getUpdatedAbatedACCCompensation(newData.paymentType, rateFull, postIncapacityEarnings, oldData.workingDays, employeeDetails?.daysPerWeek))
        const netWeeklyCompensation = (newData.actualHours || newData.additionalPayments || newData.postIncapacityEarnings) ? Number(getNetWeeklyCompensation(newData.paymentType, oldData.accWeeklyCompensation, abatedACCWeeklyCompensation, postIncapacityEarnings, rate20)) : 0
        // ordinary pay is normally populated with LTE rate however to cater for migrated claims, handle the
        // logic for employees without annualized hours
        const applicableOrdinaryPay = (formData.annualizedHours != 0) ? oldData.ordinaryPay : (oldData.ordinaryPay || LTErateFull); 
        newData.additionalPayments = Number(newData.additionalPayments.toFixed(2))
        newData.postIncapacityEarnings = isNaN(postIncapacityEarnings) ? 0 : Number(postIncapacityEarnings.toFixed(2))
        newData.rate20 = Number(rate20.toFixed(2))

        //Update compensation only when we have either one of below else default to original
        if (newData.additionalPayments || newData.actualHours || newData.postIncapacityEarnings) {
            newData.abatedACCWeeklyCompensation = isNaN(abatedACCWeeklyCompensation) ? 0 : Number(abatedACCWeeklyCompensation.toFixed(2))
            newData.netWeeklyCompensation = isNaN(netWeeklyCompensation) ? 0 : Number(netWeeklyCompensation.toFixed(2))
        } else {
            newData.netWeeklyCompensation = JSON.parse(localStorage.getItem("weekData"))[oldData.weeks]
        }

        //find and update the changed payment record
        let newPayments = weeklyCompAPIData.map(row => {
            const item = [newData].find(({ startOfPayrollPeriod, endOfPayrollPeriod, startOfWeek, endOfWeek }) =>
                startOfPayrollPeriod === row.startOfPayrollPeriod &&
                endOfPayrollPeriod === row.endOfPayrollPeriod &&
                startOfWeek === row.startOfWeek &&
                endOfWeek === row.endOfWeek
            )
            return item ? item : row
        });

        //This is totals line to change
        let totalToChange = newPayments.filter(line =>
            line.startOfPayrollPeriod === newData.startOfPayrollPeriod &&
            line.endOfPayrollPeriod === newData.endOfPayrollPeriod && !line.startOfWeek
        )
        totalToChange = JSON.parse(JSON.stringify(totalToChange));

        if(totalToChange.length > 0){
            //Resetting this totals array to default to avoid multiple addition/substractions
            let newTotals = setDefaultTotals(totalToChange[0])

            //Get all the payments to sum up the totals excluding Stand Down(D) for net compensation total
            const filteredPaymentsForCompensation = newPayments.filter(line =>
                line.startOfPayrollPeriod === newData.startOfPayrollPeriod &&
                line.endOfPayrollPeriod === newData.endOfPayrollPeriod &&
                line.startOfWeek &&
                ((line.PaymentType && line.PaymentType !== "D") || (line.paymentType && line.paymentType !== "D"))
            )

            for (let i = 0; i < filteredPaymentsForCompensation.length; i++) {
                newTotals.netCompensation += Number(filteredPaymentsForCompensation[i].netWeeklyCompensation || 0);
            }

            //Get all the payments to sum up the totals for hours worked,
            //additional payments and post incapacity earnings
            const filteredPaymentsForSum = newPayments.filter(line =>
                line.startOfPayrollPeriod === newData.startOfPayrollPeriod &&
                line.endOfPayrollPeriod === newData.endOfPayrollPeriod &&
                line.startOfWeek
            )

            for (let i = 0; i < filteredPaymentsForSum.length; i++) {
                if(filteredPaymentsForSum[i].paymentType !== "D"){
                    newTotals.actualHours += Number(filteredPaymentsForSum[i].actualHours)
                    newTotals.additionalPayments += Number(filteredPaymentsForSum[i].additionalPayments)
                    newTotals.postIncapacityEarnings += Number(filteredPaymentsForSum[i].postIncapacityEarnings)
                }
            }

            newTotals.actualHours = Number(newTotals.actualHours.toFixed(2));
            newTotals.additionalPayments = Number(newTotals.additionalPayments.toFixed(2));
            newTotals.postIncapacityEarnings = Number(newTotals.postIncapacityEarnings.toFixed(2));

            //Get all the payments for stand down period from accWeeklyCompensation
            const filteredPaymentsForStandDown = newPayments.filter(line =>
                line.startOfPayrollPeriod === newData.startOfPayrollPeriod &&
                line.endOfPayrollPeriod === newData.endOfPayrollPeriod &&
                line.startOfWeek &&
                ((line.PaymentType && line.PaymentType === "D") || (line.paymentType && line.paymentType === "D"))
            )
            let standDownPayment = 0;
            for (let i = 0; i < filteredPaymentsForStandDown.length; i++) {
                standDownPayment += Number(filteredPaymentsForStandDown[i].accWeeklyCompensation || 0)
            }

            newTotals.netCompensation = Number((newTotals.netCompensation).toFixed(2))
            
            const employeePaymentType = employeeDetails?.paymentType;
            const injuryType = formData?.injury?.value;

            // Calculate proportional ordinary pay since the payment weeks may not cover the entire 
            // payperiod 
            let paymentsInSamePeriod = newPayments.filter(line =>
                line.startOfPayrollPeriod === newData.startOfPayrollPeriod &&
                line.endOfPayrollPeriod === newData.endOfPayrollPeriod && 
                line.startOfWeek
            )
            let workingDaysInPayPeriod = 0;
            for(let i = 0; i < paymentsInSamePeriod.length; i++){
                workingDaysInPayPeriod += Number(paymentsInSamePeriod[i].workingDays);
            }
            const payPeriodHours = employeeDetails?.payFrequency === 'M' ? formData.annualizedHours / 12 : employeeDetails?.payFrequency === 'F' ? formData.annualizedHours / 26 : formData.annualizedHours / 52
            let proportionalOrdinaryPay;
            if(formData.annualizedHours != 0){
                const hoursWorked = Math.min(workingDaysInPayPeriod * employeeDetails?.hrsPerDay, payPeriodHours)
                proportionalOrdinaryPay = (applicableOrdinaryPay) * hoursWorked / payPeriodHours;
            }else if(employeeDetails?.daysPerWeek){
                // LTE Rate full is for 1 week. Hence it is ok when multiplied by workingDays across 2 or more weeks
                // depending on the pay frequency to get the proportional amount for the pay period
                proportionalOrdinaryPay = (applicableOrdinaryPay) * workingDaysInPayPeriod / employeeDetails?.daysPerWeek;
            }else{
                proportionalOrdinaryPay = 0;
            }
            if(employeePaymentType === PAY_TYPE.Salaried ||
                (employeePaymentType === PAY_TYPE.Waged && (injuryType === INJURY_TYPE.Work || (injuryType === INJURY_TYPE.Nonwork && isPSLTopUp)))){
                if(newData.paymentType !== "D"){  
                  newTotals.topUp = Number(proportionalOrdinaryPay) - Number(newTotals.postIncapacityEarnings) - Number(newTotals.netCompensation) - Number(standDownPayment)
                }
                //TopUp must not be a negative figure
                if(Number(newTotals.topUp) <= 0) {
                    newTotals.topUp = 0
                } else {
                    newTotals.topUp = Number(newTotals.topUp).toFixed(2)
                }
                let hourlyRate = formData.hourlyRate || employeeDetails?.hourlyRate;
                if(isPSLTopUp && hourlyRate > 0){
                    newTotals.pslHours = (hourlyRateTopUp == 0) ? 0 : (Number(newTotals.topUp / hourlyRate)).toFixed(2)
                }
                // newTotals.pslClosingBalance = (Number(formData.pslOpeningBalance || 0) - Number(newTotals.pslHours)).toFixed(2)
            }

            //find and update the changed totals record
            newPayments = newPayments.map(line => {
                const item = [newTotals].find(({ startOfPayrollPeriod, endOfPayrollPeriod }) =>
                    line.startOfPayrollPeriod === newTotals.startOfPayrollPeriod &&
                    line.endOfPayrollPeriod === newTotals.endOfPayrollPeriod
                    && !line.startOfWeek
                )
                return item ? item : line
            });
        }

        setWeeklyCompAPIData(sortAndFormatCalculations(newPayments))
        if (isUpdateToClaim && formData.reasonForUpdate && validReasonToCall.includes(formData.reasonForUpdate.value)) {
            // console.log("UPDATE - WHEN CALLING CALC CLAIM API")
            store.dispatch(setReCalcPayments(newPayments))
            store.dispatch(setReCalcTotals(newPayments))            
        } else if (isUpdateToClaim) {
            // console.log("UPDATE - WHEN NOT CALLING CALC CLAIM API")
            store.dispatch(setCalcPayments(newPayments))
            store.dispatch(setCalcTotals(newPayments))
        } else if (isCreateClaim) {
            // console.log("CREATE - WHEN CREATING CLAIM")
            store.dispatch(setNewClaimPayments(newPayments))
            store.dispatch(setNewClaimTotals(newPayments))
        }
        // setDataChanged(true);
        store.dispatch(setDataChanged(true));
    }

    const setDefaultTotals = (array) => {
        array.actualHours = 0
        array.additionalPayments = 0
        array.postIncapacityEarnings = 0
        //DO not display totals for Abated ACC Compensation
        //delete array.abatedACCCompensation
        array.netCompensation = 0
        return array
    }

    //Sets original net comp data and week locally in KV pair
    const setWeekData = (week, netComp) => {
        const data = localStorage.getItem("weekData") ? JSON.parse(localStorage.getItem("weekData")) : {}
        if (!data[week]) data[week] = netComp
        localStorage.setItem("weekData", JSON.stringify(data))
    }

    const fixWagedWithoutAnnualizedHoursDetails = (ordinaryPay = 0) => {
        if ([EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14].includes(employeeDetails.group)) {
            change('ordinaryPay', ordinaryPay)
            setOrdinaryPay(ordinaryPay)
        }
    }

    const setLatestSValues = (payments) => {
        let filteredPayments = payments.sort((b, a) => (a.endOfWeek > b.endOfWeek) ? 1 : ((b.endOfWeek > a.endOfWeek) ? -1 : 0))
            .filter(row => row.PaymentType == "S" || row.paymentType == 'S')
        if (filteredPayments.length) {
            // change("earning4weeks",filteredPayments[0].ordinaryPay) might be some change
            // change("unpaidDaysFourWeeks",filteredPayments[0].unpaidDays)
            // change("accDaysFourWeeks",filteredPayments[0].accDays)
            // change("otherEarningsFourWeeks",filteredPayments[0].otherEarnings)
            // change("unpaidDaysFourWeeksPrevEmployer",filteredPayments[0].unpaidDaysPreviousEmp)
            // change("divisor4weeks",filteredPayments[0].divisor)
            // change("overwriteDivisor4Weeks",filteredPayments[0].overwriteDivisor)
            change("steRate100", filteredPayments[0].rateFull)
            change("steRate80", filteredPayments[0].rate)
            setSTERateFull(filteredPayments[0].rateFull)
        }
    }

    const setLatestLValues = (claimData) => {
        let filteredPayments = claimData?.payments.sort((b, a) => (a.endOfWeek > b.endOfWeek) ? 1 : ((b.endOfWeek > a.endOfWeek) ? -1 : 0))
            .filter(row => row.PaymentType == "L" || row.paymentType == 'L')
        if (filteredPayments.length) {
            // change("earning52weeks",filteredPayments[0].ordinaryPay)
            // change("unpaidDays52Weeks",filteredPayments[0].unpaidDays)
            // change("accDays52Weeks",filteredPayments[0].accDays)
            // change("otherEarnings52weeks",filteredPayments[0].otherEarnings)
            // change("unpaidDays52WeeksPreviousEmployer",filteredPayments[0].unpaidDaysPreviousEmp)
            // change("divisor52weeks",filteredPayments[0].divisor)
            // change("overwriteDivisor52Weeks",filteredPayments[0].overwriteDivisor)
            change("lteRate100", filteredPayments[0].rateFull)
            change("lteRate80", filteredPayments[0].rate)
            setLTERateFull(filteredPayments[0].rateFull)
        }else{
            change("lteRate100",  claimData?.rateFullLte || claimData?.claim?.rateFullLte)
            setLTERateFull(claimData?.rateFullLte || claimData?.claim?.rateFullLte)
        }
    }
    
    useEffect(() => {
        try {
            let pslTopUpText;
            if (isUpdateToClaim || isViewClaim) {
                if ((formData.reasonForUpdate && validReasonToCall.includes(formData.reasonForUpdate.value))) {
                    setWeeklyCompAPIData(sortAndFormatCalculations([...claimData.payments, ...claimData.totals]))
                    // if(localStorage.getItem("totals") === null) localStorage.setItem('totals',JSON.stringify(claimData.totals))
                    // console.log(claimData.totals)
                    change("indexationRateMin", claimData.minimumIndexation)
                    change("indexationRateMax", claimData.maximumIndexation)
                    setLatestSValues(claimData.payments)
                    setLatestLValues(claimData)
                    change("standDownPeriod", formatDateAsText(claimData.standDownStart) + " - " + formatDateAsText(claimData.standDownEnd))
                    change("startDateOfWeeklyCompensation", formatDateAsText(claimData.weeklyCompensationStart))
                    change("steCalculation", formatDateAsText(claimData.stePeriodStart) + " - " + formatDateAsText(claimData.stePeriodEnd))
                    change("lteCalculation", formatDateAsText(claimData.ltePeriodStart) + " - " + formatDateAsText(claimData.ltePeriodEnd))
                    fixWagedWithoutAnnualizedHoursDetails(claimData.ordinaryPay, claimData.abatementHourlyRate)
                    setIndexationExemptionDates(claimData.indexationExemptionDates?.map(row => {
                        row.fromDate = formatDateColumn(row.fromDate);
                        row.toDate = formatDateColumn(row.toDate);
                        return row;}));
                    pslTopUpText = formData.pslTopUp === true || formData.pslTopUp === 'true' ? "Yes" : "No";
                }
                else {
                    setWeeklyCompAPIData(sortAndFormatCalculations([...updateClaimData.updateClaimAPIData.payments, ...updateClaimData.updateClaimAPIData.payperiodtotals]))
                    // if(localStorage.getItem("totals") === null) localStorage.setItem('totals',JSON.stringify(updateClaimData.updateClaimAPIData.payperiodtotals))
                    // console.log(updateClaimData.updateClaimAPIData.payperiodtotals)
                    change("indexationRateMin", updateClaimData.updateClaimAPIData.claim.minimumIndexation)
                    change("indexationRateMax", updateClaimData.updateClaimAPIData.claim.maximumIndexation)
                    setLatestSValues(updateClaimData.updateClaimAPIData.payments)
                    setLatestLValues(updateClaimData.updateClaimAPIData)
                    change("standDownPeriod", formatDateAsText(updateClaimData.updateClaimAPIData.claim.standDownStart) + " - " + formatDateAsText(updateClaimData.updateClaimAPIData.claim.standDownEnd))
                    change("startDateOfWeeklyCompensation", formatDateAsText(updateClaimData.updateClaimAPIData.claim.weeklyCompensationStart))
                    change("steCalculation", formatDateAsText(updateClaimData.updateClaimAPIData.claim.stePeriodStart) + " - " + formatDateAsText(updateClaimData.updateClaimAPIData.claim.stePeriodEnd))
                    change("lteCalculation", formatDateAsText(updateClaimData.updateClaimAPIData.claim.ltePeriodStart) + " - " + formatDateAsText(updateClaimData.updateClaimAPIData.claim.ltePeriodEnd))
                    setIndexationExemptionDates(updateClaimData.updateClaimAPIData.indexationExemptionDates?.map(row => {
                        row.fromDate = formatDateColumn(row.fromDate);
                        row.toDate = formatDateColumn(row.toDate);
                        return row;}))
                    if(updateClaimData?.updateClaimAPIData?.claim?.pslTopUp === 'X' || updateClaimData?.updateClaimAPIData?.claim?.pslTopUp === true || updateClaimData?.updateClaimAPIData?.claim?.pslTopUp === 'true'){
                        pslTopUpText = "Yes";
                        setIsPSLTopup(true);
                    }else{ 
                        pslTopUpText = "No";
                        setIsPSLTopup(false);
                    }
                }
                setERAOptOut(updateClaimData?.updateClaimAPIData?.claim?.eraOptOut === "X");
                setMigratedClaim(updateClaimData?.updateClaimAPIData?.claim?.migratedClaim === "X");
            } else {
                setWeeklyCompAPIData(sortAndFormatCalculations([...claimData.payments, ...claimData.totals]))
                // if(localStorage.getItem("totals") === null) localStorage.setItem('totals',JSON.stringify(claimData.totals))
                // console.log(claimData.totals)
                change("indexationRateMin", claimData.minimumIndexation)
                change("indexationRateMax", claimData.maximumIndexation)
                setLatestSValues(claimData.payments)
                setLatestLValues(claimData)
                change("standDownPeriod", formatDateAsText(claimData.standDownStart) + " - " + formatDateAsText(claimData.standDownEnd))
                change("startDateOfWeeklyCompensation", formatDateAsText(claimData.weeklyCompensationStart))
                change("steCalculation", formatDateAsText(claimData.stePeriodStart) + " - " + formatDateAsText(claimData.stePeriodEnd))
                change("lteCalculation", formatDateAsText(claimData.ltePeriodStart) + " - " + formatDateAsText(claimData.ltePeriodEnd))
                fixWagedWithoutAnnualizedHoursDetails(claimData.ordinaryPay, claimData.abatementHourlyRate)
                setIndexationExemptionDates(claimData.indexationExemptionDates?.map(row => {
                    row.fromDate = formatDateColumn(row.fromDate);
                    row.toDate = formatDateColumn(row.toDate);
                    return row;}));
                setERAOptOut(formData?.optOutClaim?.value === 'X');
                pslTopUpText = formData.pslTopUp === true || formData.pslTopUp === 'true' ? "Yes" : "No";
            }
            
            change("pslTopUpText", pslTopUpText)
        } catch (e) { }
        return () => {
            change("indexationRateMin", null)
            change("indexationRateMax", null)
            change("standDownPeriod", null)
            change("startDateOfWeeklyCompensation", null)
            change("steCalculation", null)
            change("lteCalculation", null)
            setIndexationExemptionDates(formData?.indexationExemptionDates?.map(row => {
                row.fromDate = formatDateColumn(row.fromDate);
                row.toDate = formatDateColumn(row.toDate);
                return row;}));
        }
    }, [claimData]);

    useEffect(() => {
        return () => {
            setWeeklyCompAPIData([])
            localStorage.removeItem("totals")
        }
    }, [])

    useEffect(() => {
        setMappedNotes(getMappedNotes(updateClaimData?.updateClaimAPIData?.notes));
    },[updateClaimData])

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        {/*<h5 className="bold-text">{t('ui_elements.collapse.boxed_collapse_full_width')}</h5>*/}
                    </div>
                    {/* <Prompt
                        when={dataChanged}
                        message={location => `Changes made will be lost. Are you sure you want to go to ${location.pathname} ?`}
                    /> */}
                    <ButtonToolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {!isViewClaim && <Button color="primary" type="button" className="previous" onClick={previousPage}>Back</Button>}
                        <Button type="button" className="btn-outline-danger" name='expand' onClick={handleCollapse}>Expand All</Button>
                        <Button type="button" className="btn-outline-danger" name='collapse' onClick={handleCollapse}>Collapse All</Button>
                    </ButtonToolbar>
                    <Collapse title="Employee Information" className="boxed with-shadow" isCollapsed={isCollapsed}>
                        <form className="form form--horizontal" onSubmit={handleSubmit}>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Employee Number</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="employeeNumber"
                                            component="input"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">First Name</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="firstName"
                                            component="input"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Last Name</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="lastName"
                                            component="input"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Pay Category</span>
                                    <div className="form__form-group-field">
                                        <input
                                            disabled
                                            value={employeeDetails.payCategory}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Employment Type</span>
                                    <div className="form__form-group-field">
                                        <input
                                            disabled
                                            value={employeeDetails.employmentType + ' / ' + employeeDetails?.employmentTypeDescription}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Pay Type</span>
                                    <div className="form__form-group-field">
                                        <input
                                            disabled
                                            value={employeeDetails?.paymentTypeDescription}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Pay Cycle</span>
                                    <div className="form__form-group-field">
                                        <input
                                            disabled
                                            value={employeeDetails.payCycle + ' / ' + employeeDetails?.payCycleDescription}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Pay Frequency</span>
                                    <div className="form__form-group-field">
                                        <input
                                            disabled
                                            value={employeeDetails.payFrequencyDescription}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Hire Date</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="date"
                                            disabled
                                            value={employeeDetails.hireDate}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">IRD Number</span>
                                    <div className="form__form-group-field">
                                        <input
                                            disabled
                                            value={employeeDetails.irdNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Collapse>
                    <Collapse title="Claim Information" className="boxed with-shadow" isCollapsed={isCollapsed}>
                        <form className="form form--horizontal">
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Claim Number</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="claimNumber"
                                            component="input"
                                            type="text"
                                            placeholder="Claim Number"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">ServiceNow Request</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="serviceNowRequest"
                                            component="input"
                                            type="text"
                                            placeholder="ServiceNow Request"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Work/Non-Work?</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="injury"
                                            component={renderSelectField}
                                            type="text"
                                            placeholder="Work/Non-Work"
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Medical Cert Status</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="accStatus"
                                            component={renderSelectField}
                                            type="text"
                                            placeholder="Medical Cert Status"
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>
                                <div hidden={!(formData.claimType.value === 'DOSI')} className="form__form-group">
                                    <span className="form__form-group-label">Date of Original Injury</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="dofiDate"
                                            component="input"
                                            type="date"
                                            disabled
                                            placeholder="DOFI Date"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Calculation Status</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="calculationStatus"
                                            component="input"
                                            type="text"
                                            placeholder="Calculation Status"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">PSL Top Up</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="pslTopUpText"
                                            component="input"
                                            type="text" 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Claim Type</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="claimType"
                                            id="claimType"
                                            component={renderSelectField}
                                            type="text"
                                            placeholder="Claim Type"
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">DOFI/DOSI Date</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="dateOfInjury"
                                            component="input"
                                            type="date"
                                            placeholder="DOFI/DOSI Date"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Medical Expiry Date</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="certificateValidTo"
                                            component="input"
                                            type="date"
                                            placeholder="16/09/2021"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Calculation Basis</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="calculationBasis"
                                            component="input"
                                            type="text"
                                            placeholder="Calculation Basis"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Variance Amount</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="varianceAmount"
                                            component="input"
                                            type="number"
                                            placeholder="0"
                                        />
                                    </div>
                                </div>
                                <div hidden={formData.reasonForUpdate} className="form__form-group">
                                    <span className="form__form-group-label">Reason For Update</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="reasonForUpdate"
                                            component="input"
                                            type="text"
                                            placeholder="Reason for Update"
                                        />
                                    </div>
                                </div>
                                <div hidden={!migratedClaim} className="form__form-group">
                                    <span className="form__form-group-label">Migrated Claim</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="migratedClaim"
                                            component="input"
                                            type="text"
                                            placeholder="No"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Collapse>
                    <Collapse title="Contract Information" className=" boxed with-shadow" isCollapsed={isCollapsed}>
                        <form className="form form--horizontal">
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Days per Week</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="daysPerWeek"
                                            component={renderSelectField}
                                            type="text"
                                            placeholder="Days Per Week"
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Hours Per day</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="hoursPerDay"
                                            component="input"
                                            type="number"
                                            placeholder="Hours per Day"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Annualized Hours</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="annualizedHours"
                                            component="input"
                                            type="number"
                                            placeholder="annualized hours"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div hidden={!(employeeDetails.payFrequency == "M")} className="form__form-group">
                                    <span className="form__form-group-label">Monthly Hours/Paid Hours Per Month</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="hoursPerMonth"
                                            component="input"
                                            type="number"
                                            placeholder="Monthly Hours"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Weekly Hours</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="weeklyHours"
                                            component="input"
                                            type="number"
                                            disabled
                                            placeholder="weekly hours"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Annual Salary</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="annualSalary"
                                            component="input"
                                            type="number"
                                            placeholder="annual salary"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Ordinary Pay</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="ordinaryPay"
                                            component="input"
                                            type="number"
                                            placeholder="Ordinary Pay"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Employment Weeks</span>
                                    <div className="form__form-group-field">
                                    <Field
                                        name="employmentWeeks"
                                        component="input"
                                        type="text"
                                        placeholder="123456"                                        
                                        disabled
                                    />
                                    </div>
                                </div>
                            </div>
                            <div className="form__half">
                                <div hidden={!(employeeDetails.payFrequency == "F")} className="form__form-group">
                                    <span className="form__form-group-label">Fortnightly Hours/Paid Hours Per Fortnight</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="hoursPerFornight"
                                            component="input"
                                            type="number"
                                            placeholder="Fortnightly Hours"
                                            disabled
                                        />
                                    </div>
                                </div>                                
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Effective Date Of Ordinary Pay </span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="effectiveDate"
                                            disabled
                                            component="input"
                                            type="date"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Effective date of PSL Top Up</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="datePSLBalance"
                                            disabled
                                            component="input"
                                            type="date"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Hourly Rate</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="hourlyRate"
                                            disabled
                                            component="input"
                                        />
                                    </div>
                                </div>
                                <div hidden={!(employeeDetails.paymentType == "W")} className="form__form-group">
                                    <span className="form__form-group-label">Hours per day for Abatement</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="hrsPerDayForAbatement"
                                            component="input"
                                            type="text"
                                            placeholder="hours per day for abatement"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div hidden={(formData.annualizedHours)} className="form__form-group">
                                    <span className="form__form-group-label">Stand Down Hours</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="standDownHours"
                                            component="input"
                                            type="number"
                                            placeholder="123456"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Collapse>
                    <Collapse title="Key Dates" className=" boxed with-shadow" isCollapsed={isCollapsed}>
                        <form className="form form--horizontal">
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Stand Down Period</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="standDownPeriod"
                                            component="input"
                                            // type="date"
                                            placeholder="dd/mm/yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Start Date of Weekly Compensation</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="startDateOfWeeklyCompensation"
                                            component="input"
                                            // type="date"
                                            placeholder="dd/mm/yyyy"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form__half">
                                <div hidden={!(formData.calculationBasis == 'STE' || formData.calculationBasis == 'STE & LTE')} className="form__form-group">
                                    <span className="form__form-group-label">STE Calculation Period</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="steCalculation"
                                            component="input"
                                            // type="date"
                                            placeholder="steCalculation"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div hidden={!(formData.calculationBasis == 'LTE' || formData.calculationBasis == 'STE & LTE')} className="form__form-group">
                                    <span className="form__form-group-label">LTE Calculation Period</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="lteCalculation"
                                            component="input"
                                            // type="date"
                                            placeholder="LTE Calculation"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Collapse>
                    <Collapse title="Earnings Information last 4 weeks" className={`${(formData.calculationBasis == 'STE' || formData.calculationBasis == 'STE & LTE') ? '' : 'hidden '} boxed with-shadow`} isCollapsed={isCollapsed}>
                        <form className="form form--horizontal">
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Ordinary Pay Earnings</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="earning4weeks"
                                            component="input"
                                            type="number"
                                            placeholder="ordinary pay earnings"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Unpaid Days</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="unpaidDaysFourWeeks"
                                            component="input"
                                            type="number"
                                            placeholder="Unpaid Days"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">ACC Days</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="accDaysFourWeeks"
                                            component="input"
                                            type="number"
                                            placeholder="acc days"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Other Earnings (outside ordinary pay)</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="otherEarningsFourWeeks"
                                            component="input"
                                            type="number"
                                            placeholder="other earnings"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Unpaid Days (previous employer)</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="unpaidDaysFourWeeksPrevEmployer"
                                            component="input"
                                            type="number"
                                            disabled
                                            placeholder="unpaid days"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Divisor</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="divisor4weeks"
                                            component="input"
                                            type="number"
                                            placeholder="divisor"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Overwrite Divisor</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="overwriteDivisor4Weeks"
                                            component="input"
                                            type="number"
                                            placeholder="overwrite Divisor"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Collapse>
                    <Collapse title="Earnings Information last 52 weeks" className={`${(formData.calculationBasis == 'LTE' || formData.calculationBasis == 'STE & LTE' || (formData.calculationBasis == 'STE' && (formData.annualizedHours == 0 || formData.annualizedHours === undefined))) ? '' : 'hidden '} boxed with-shadow`} isCollapsed={isCollapsed}>
                        <form className="form form--horizontal">
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Ordinary Pay Earnings</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="earning52weeks"
                                            component="input"
                                            type="number"
                                            placeholder="ordinary pay earnings"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Unpaid Days</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="unpaidDays52Weeks"
                                            component="input"
                                            type="number"
                                            placeholder="Unpaid Days"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">ACC Days</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="accDays52Weeks"
                                            component="input"
                                            type="number"
                                            placeholder="acc days"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Other Earnings (outside ordinary pay)</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="otherEarnings52Weeks"
                                            component="input"
                                            type="number"
                                            placeholder="other earnings"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Unpaid Days (previous employer)</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="unpaidDays52WeeksPreviousEmployer"
                                            component="input"
                                            type="number"
                                            disabled
                                            placeholder="unpaid days"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Divisor</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="divisor52weeks"
                                            component="input"
                                            type="number"
                                            placeholder="divisor"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Overwrite Divisor</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="overwriteDivisor52Weeks"
                                            component="input"
                                            type="number"
                                            placeholder="overwrite Divisor"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Collapse>
                    <Collapse title="ACC Rates Calculation" className=" boxed with-shadow" isCollapsed={isCollapsed}>
                        <form className="form form--horizontal">
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Employment Weeks</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            disabled
                                            name="employmentWeeks"
                                            component="input"
                                            type="number"
                                            placeholder="Employment Weeks"
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Indexation Rate Min</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="indexationRateMin"
                                            component="input"
                                            type="number"
                                            placeholder="Indexation Rate Min"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Indexation Rate Max</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="indexationRateMax"
                                            component="input"
                                            type="number"
                                            placeholder="Indexation Rate Max"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`${(formData.calculationBasis == 'STE' || formData.calculationBasis == 'STE & LTE') ? '' : 'hidden '} form__form-group`}>
                                    <span className="form__form-group-label">STE Rate 80%</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="steRate80"
                                            component="input"
                                            type="number"
                                            placeholder="STE Rate 80%"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form__half">
                                <div className={`${(formData.calculationBasis == 'LTE' || formData.calculationBasis == 'STE & LTE') ? '' : 'hidden '} form__form-group`}>
                                    <span className="form__form-group-label">LTE Rate 80%</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="lteRate80"
                                            component="input"
                                            type="number"
                                            disabled
                                            placeholder="LTE Rate 80%"
                                        />
                                    </div>
                                </div>
                                <div className={`${(formData.calculationBasis == 'STE' || formData.calculationBasis == 'STE & LTE') ? '' : 'hidden '} form__form-group `}>
                                    <span className="form__form-group-label">STE Rate 100%</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="steRate100"
                                            component="input"
                                            type="number"
                                            placeholder="STE Rate 100%"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={`${(formData.calculationBasis == 'LTE' || formData.calculationBasis == 'STE & LTE' || (formData.calculationBasis == 'STE' && (formData.annualizedHours == 0 || formData.annualizedHours === undefined))) ? '' : 'hidden '} form__form-group`}>
                                    <span className="form__form-group-label">LTE Rate 100%</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="lteRate100"
                                            component="input"
                                            type="number"
                                            placeholder="LTE Rate 100%"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Collapse>
                    <Collapse title="Indexation Exemption Dates" className={`${((indexationExemptionDates?.length > 0) ? '' : 'hidden ')} boxed with-shadow`} isCollapsed={isCollapsed}>
                    {/* (formData?.indexationExemptionDates && formData?.indexationExemptionDates.length > 0) */}
                        <MaterialTable
                            columns={indexationColumns}
                            data={indexationExemptionDates}
                                // {(formData && formData.indexationExemptionDates)?
                                //     JSON.parse(JSON.stringify(formData.indexationExemptionDates)).map(row => {
                                //         row.fromDate = formatDateColumn(row.fromDate);
                                //         row.toDate = formatDateColumn(row.toDate);
                                //         return row;}) : []}
                            title="Indexation Exemption Dates"
                            options={{     
                                pageSize: 1,
                                search:false,
                                toolbar:false
                            }}
                        />
                    </Collapse>
                    <Collapse className={`${( eraOptOut ? '' : 'hidden ')} boxed with-shadow`}>
                        <form className="form">
                            <div className="form__form-group">
                                <span className="form__form-group-label opt_out">ERA Opt out Claim, Do Not Pay Compensation (80% Rate, Net Comp.) in Payroll</span>
                            </div>
                        </form>
                    </Collapse>
                    <div style={{ maxWidth: '100%' }}>
                        <MaterialTable
                            columns={columns}
                            data={weeklyCompAPIData}
                            title="Weekly Compensation Calculation"
                            options={{
                                rowStyle: rowData => ({
                                    backgroundColor: (rowData.startOfWeek) ? '#FFF' : '#EEE'
                                }),
                                pageSize: 20,
                                headerStyle: { position: 'sticky', top: 0 }, maxBodyHeight: '750px'
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        handleCompensationCalc(newData, oldData)
                                        resolve()
                                    }),
                                isEditable: (rowData) => (rowData.startOfWeek && !isViewClaim)
                            }}
                            localization={{body: { emptyDataSourceMessage: "Calculating payments..."}}}
                        />
                        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                            {(!isUpdateToClaim && !isViewClaim) && <Button color="primary" type="submit" onClick={handleSubmit} disabled={saveDisable} className="next">Save & Finish</Button>}
                            {(isUpdateToClaim && !isViewClaim) && <Button color="primary" type="submit" onClick={handleSubmit} className="next">Update</Button>}
                        </ButtonToolbar>
                    </div>
                    <Collapse title="Notes" className={`${((isUpdateToClaim || isViewClaim) && (updateClaimData?.updateClaimAPIData?.notes.length > 0) ? '' : 'hidden ')} boxed with-shadow`} isCollapsed={isCollapsed}>
                        {(isUpdateToClaim || isViewClaim) && mappedNotes?.map((row, key) => {
                            return (
                                <Card style={{ border: '1px dotted grey', padding: '5px', marginTop: '10px' }}>
                                    <CardHeader style={{ padding: '10px' }}>{row.changedBy + " - " + row.changedOn}</CardHeader>
                                    <CardTitle tag="h4" style={{ padding: '10px' }}>{row.note}</CardTitle>
                                </Card>
                            )
                        })}
                    </Collapse>
                </CardBody>
            </Card>
        </Col>
    );
};

WizardFormResults.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'wizard', //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormResults);
