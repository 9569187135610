import { formatdecimal2place } from '../../../../../utils/formatter'
import { formatDateForDisplay as formatDate} from '../../../../../utils/formatDate'

export const setFormFields = (change, claimData, formData) => {
    console.log(claimData)
    change("dateOfInjury", formatDate(claimData.updateClaimAPIData.claim.dateOfInjury))
    change("certificateValidTo", formatDate(claimData.updateClaimAPIData.claim.certificateValidTo))
    change("days", claimData.updateClaimAPIData.claim.numberOfDaysCovered)
    change("calculationStatus", claimData.updateClaimAPIData.claim.calculationStatus)
    change("varianceAmount", claimData.updateClaimAPIData.claim.varianceAmount)
    change("migratedClaim", claimData?.updateClaimAPIData?.claim?.migratedClaim === "X" ? "Yes" : "No")
        
    if(claimData.updateClaimAPIData.claim.calculationBasis === 'S') change("calculationBasis", "STE")
    else if(claimData.updateClaimAPIData.claim.calculationBasis === 'L') change("calculationBasis", "LTE")
    else if(claimData.updateClaimAPIData.claim.calculationBasis === 'B') change("calculationBasis", "STE & LTE")

    if(claimData.updateClaimAPIData.claim.accStatus === "Fully Unfit") change("accStatus", { value: 'Fully Unfit', label: 'Fully Unfit' })
    else if(claimData.updateClaimAPIData.claim.accStatus === "Light Duties") change("accStatus", { value: 'Light Duties', label: 'Light Duties' })
    else if(claimData.updateClaimAPIData.claim.accStatus === "Return to Work") change("accStatus", { value: 'Return to Work', label: 'Return to Work' })
    else if(claimData.updateClaimAPIData.claim.accStatus === "To be confirmed") change("accStatus", { value: 'To be confirmed', label: 'ACC Status TBC' })      
        
    if(claimData.updateClaimAPIData.claim.pslTopUp === "X") localStorage.setItem("localpslTopUp", true)
    else if(claimData.updateClaimAPIData.claim.pslTopUp === " ") localStorage.setItem("localpslTopUp", false)

    if(claimData.updateClaimAPIData.claim.working30Hours === "X") localStorage.setItem("localworking30Hours", true)
    else if(claimData.updateClaimAPIData.claim.working30Hours === " ") localStorage.setItem("localworking30Hours", false)
    
    if(claimData.updateClaimAPIData.claim.worked52Weeks === "X") localStorage.setItem("localyearlyEarnings", true)
    else if(claimData.updateClaimAPIData.claim.worked52Weeks === " ") localStorage.setItem("localyearlyEarnings", false)
    
    if(claimData.updateClaimAPIData.claim.indexationApplies == "X") change("indexation", true)    
    else if(claimData.updateClaimAPIData.claim.indexationApplies == " ") change("indexation", false)      

    if(claimData.updateClaimAPIData.claim.eraOptOut === "yes-paid by ACC") change("optOutClaim", { value: 'yes', label: 'Yes - Paid by ACC Directly' })
    else if(claimData.updateClaimAPIData.claim.eraOptOut === "no-payroll") change("optOutClaim", { value: 'no', label: 'No - Payroll Pays' })

    setPage3FormFields(change, claimData.updateClaimAPIData.payments, formData, claimData)
    setPage4FormFields(change, claimData.updateClaimAPIData.payperiodtotals, formData, claimData)
}

export const setPage4FormFields = (change, filteredTotals, formData, updateClaimData) => { 
    filteredTotals = filteredTotals?.sort((b,a) => (a.endOfWeek > b.endOfWeek) ? 1 : ((b.endOfWeek > a.endOfWeek) ? -1 : 0))    
    if (filteredTotals.length > 0){  
        filteredTotals = JSON.parse(JSON.stringify(filteredTotals))[0]
        change('pslOpeningBalance',filteredTotals.pslOpeningBalance)
        change('datePSLBalance',formatDate(filteredTotals.datePSLBalance))        
    }
    const hours = updateClaimData.updateClaimAPIData.employee.annualizedHours || 0;  
    updateClaimData.updateClaimAPIData.employee.payType == "S" ? change('weeklyHours',formatdecimal2place.format(2080/52)) : change('weeklyHours',formatdecimal2place.format(hours/52))
    change("hoursPerMonth",hours/12)
    change('standDownHours',updateClaimData.updateClaimAPIData.claim.standDownHours)
    new Date().getFullYear() % 4 == 0 ? change("hoursPerFornight",formatdecimal2place.format(hours/27)) : change("hoursPerFornight",formatdecimal2place.format(hours/26)) 
}

export const setPage3FormFields = (change, filteredPayments, formData, updateClaimData) => {
    filteredPayments = filteredPayments?.filter(line => line.claimNumber).sort((b,a) => (a.endOfWeek > b.endOfWeek) ? 1 : ((b.endOfWeek > a.endOfWeek) ? -1 : 0))
    if(filteredPayments && filteredPayments.length > 0){
        filteredPayments = JSON.parse(JSON.stringify(filteredPayments))
    }

    let lteValuesFound = false;
    for(let i=0;i<filteredPayments.length;i++){
        if(filteredPayments[i].paymentType === 'L'){
            change("earning52weeks", formData.earning52weeks || filteredPayments[i].earnings || 0)
            change("unpaidDays52Weeks",filteredPayments[i].unpaidDays || 0)
            change("accDays52Weeks",filteredPayments[i].accDays || 0)
            change("otherEarnings52Weeks", formData.otherEarnings52Weeks || filteredPayments[i].otherEarnings || 0)
            change("unpaidDays52WeeksPreviousEmployer",filteredPayments[i].unpaidDaysPreviousEmp || 0)
            change("divisor52weeks",filteredPayments[i].divisor || 0) 
            change("overwriteDivisor52Weeks", formData.overwriteDivisor52Weeks || filteredPayments[i].overwriteDivisor || 0)
            lteValuesFound = true;
            break
        }          
    }
    if(!lteValuesFound){
        change("earning52weeks", updateClaimData?.updateClaimAPIData?.claim?.earningsLte || 0);
        change("otherEarnings52Weeks", updateClaimData?.updateClaimAPIData?.claim?.otherEarningsLte || 0);
        change("unpaidDays52Weeks", updateClaimData?.updateClaimAPIData?.claim?.unpaidDaysLte || 0);
        change("unpaidDays52WeeksPreviousEmployer", updateClaimData?.updateClaimAPIData?.claim?.unpaidDaysPreviousEmpLte || 0);
        change("accDays52Weeks", updateClaimData?.updateClaimAPIData?.claim?.accDaysLte || 0);
        change("overwriteDivisor52Weeks", updateClaimData?.updateClaimAPIData?.claim?.overwriteDivisorLte || 0);
    }

    for(let i=0;i<filteredPayments.length;i++){
        if(filteredPayments[i].paymentType === 'S'){            
            change("earning4weeks", formData.earning4weeks || filteredPayments[i].earnings || 0)
            change("unpaidDaysFourWeeks",filteredPayments[i].unpaidDays || 0)
            change("accDaysFourWeeks",filteredPayments[i].accDays || 0)
            change("otherEarningsFourWeeks", formData.otherEarningsFourWeeks || filteredPayments[i].otherEarnings || 0)
            change("unpaidDaysFourWeeksPrevEmployer",filteredPayments[i].unpaidDaysPreviousEmp || 0)
            change("divisor4weeks",filteredPayments[i].divisor || 0)
            change("overwriteDivisor4Weeks", formData.overwriteDivisor4Weeks || filteredPayments[i].overwriteDivisor || 0)
            break
        }          
    }

    filteredPayments = filteredPayments[0]
    change("employmentWeeks",filteredPayments?.employmentWeeks  || 0)
    change("ordinaryPay",formData?.ordinaryPay || filteredPayments?.ordinaryPay  || 0)    
    change("effectiveDate", formData?.effectiveDate || formatDate(filteredPayments?.dateOrdinaryPay))
    change("annualSalary",updateClaimData?.updateClaimAPIData?.employee?.annualSalary  || 0)
    change("hourlyRate",updateClaimData?.updateClaimAPIData?.employee?.hourlyRate  || 0)
    if(updateClaimData?.updateClaimAPIData?.employee?.daysPerWeek == 5) change("daysPerWeek", { value: '5', label: '5' })
    else if(updateClaimData?.updateClaimAPIData?.employee?.daysPerWeek == 7) change("daysPerWeek", { value: '7', label: '7' })
    change("hoursPerDay",updateClaimData?.updateClaimAPIData?.employee?.hrsPerDay  || 0)
    change("annualizedHours",updateClaimData?.updateClaimAPIData?.employee?.annualizedHours  || 0)
    change("hoursPerMonth",updateClaimData?.updateClaimAPIData?.employee?.hoursPerMonth  || 0)
    change("standDownHours",updateClaimData?.updateClaimAPIData?.employee?.standDownHours  || 0)                          
    if(updateClaimData?.updateClaimAPIData?.employee?.daysPerWeek){
    change("hrsPerDayForAbatement",formatdecimal2place.format(updateClaimData?.updateClaimAPIData?.employee?.hoursPerWeek/updateClaimData.updateClaimAPIData.employee.daysPerWeek)  || 0)
    }else{
        change("hrsPerDayForAbatement",0);
    }
}