export const validReasonToCall = [
    'topUpOrdinaryPay',
    'editMedicalExpiryDate', 
    'changeRuleGrouping',
    'changePSLTopUp',
    'abatementTerminatedEmployee'
]

const UPDATE_REASON_MAPPING_MATRIX = {
    "accStatus": ['abatementLightDuties'],
    "pslTopUp": ['abatementLightDuties','changePSLTopUp'],
    "pslOpeningBalance": ['changePSLTopUp'],
    "datePSLBalance": ['changePSLTopUp'],
    "certificateValidTo": ['editMedicalExpiryDate'],
    "ordinaryPay": ['topUpOrdinaryPay'],    
    "effectiveDate": ['topUpOrdinaryPay'],
    "earning4weeks": ['editLTESTEEarningsAwaitingDecision', 'editLTESTEEarningsMismatchACC'],
    "earning52weeks": ['editLTESTEEarningsAwaitingDecision', 'editLTESTEEarningsMismatchACC'],
    "otherEarningsFourWeeks": ['editLTESTEEarningsAwaitingDecision', 'editLTESTEEarningsMismatchACC'],
    "otherEarnings52Weeks": ['editLTESTEEarningsAwaitingDecision', 'editLTESTEEarningsMismatchACC'],
    "overwriteDivisor4Weeks": ['editLTESTEEarningsAwaitingDecision', 'editLTESTEEarningsMismatchACC'],
    "overwriteDivisor52Weeks": ['editLTESTEEarningsAwaitingDecision', 'editLTESTEEarningsMismatchACC'],
    "indexationExemptionDates": ['abatementTerminatedEmployee']
}

export const isValidEditableReason = (reason = null, field) => {
    if(!reason) return false  
    return UPDATE_REASON_MAPPING_MATRIX[field].includes(reason)
}  